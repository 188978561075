import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguage } from "../../../../store/language/actions";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate } from "react-router-dom";
import { AddTag, uploadFile } from "../../../../store/actions";
const AddTags = () => {
  const { loading } = useSelector((state) => state.Subscription);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState([]);
  const [isDisabled,setIsDisabled]=useState(false)
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    gender: "",
    titles: [
      {
        language: "",
        title: "",
      },
    ],
  });
  const fetchLanguages = async () => {
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
        console.error(err);
      } else {
        setLanguage(res);
      }
    };
    dispatch(getAllLanguage({ callback }));
  };
  useEffect(() => {
    fetchLanguages();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };
  const handleTitleTypeChange = (index, field, value) => {
    const updatedTitles = [...formData.titles];
    updatedTitles[index] = {
      ...updatedTitles[index],
      [field]: value,
    };
    setFormData({ ...formData, titles: updatedTitles });
    setErrors((prev) => ({
      ...prev,
      [`titles-${index}-${field}`]: "",
    }));
  };
  const addTitleType = () => {
    setFormData({
      ...formData,
      titles: [...formData.titles, { language: "", title: "" }],
    });
  };
  const removeTitleType = (index) => {
    const updatedTitles = formData.titles.filter((_, i) => i !== index);
    setFormData({ ...formData, titles: updatedTitles });
  };
  const validateFormData = () => {
    const newErrors = {};
    if (!formData.type) newErrors.type = "Type is required";
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    formData.titles.forEach((title, index) => {
      if (!title.language)
        newErrors[`titles-${index}-language`] = "Language is required";
      if (!title.title) newErrors[`titles-${index}-title`] = "Title is required";
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0
  };
  const handleSubmit = (e) => {
    setIsDisabled(true)
    e.preventDefault();
    if (!validateFormData()) {
      setIsDisabled(false); 
      return;
    }

    const callback = (err, res) => {
      if (err) {
        toast.error(err);
        console.error("Error submitting:", err);
      } else {
        toast.success(res.message);
        setIsDisabled(false)
        navigate("/tags");
        setFormData({
          name: "",
          type: "",
          gender: "",
          titles: [
            {
              language: "",
              title: "",
            },
          ],
        });
      }
    };
    dispatch(AddTag({ data: formData, callback }));
  };
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form className="mx-auto" onSubmit={handleSubmit}>
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Add Tag:
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center">
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="type"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Tag Type
                          </label>
                          <select
                            className="form-control"
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                          >
                            <option value="">Select tag type</option>
                            <option value="tag">Tag</option>
                            <option value="personality">Personality</option>
                            <option value="hobby">Hobby</option>
                            <option value="cloths">Cloths</option>
                            <option value="background">Background</option>
                            <option value="occupation">Occupation</option>
                          </select>
                          {errors.type && (
                            <small className="text-danger">{errors.type}</small>
                          )}
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="gender"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Gender
                          </label>
                          <select
                            className="form-control"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="all">All</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          {errors.gender && (
                            <small className="text-danger">{errors.gender}</small>
                          )}
                        </Col>
                        <Col md="12" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Prompt Value
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <small className="text-danger">{errors.name}</small>
                          )}
                        </Col>
                        {formData.titles.map((item, index) => (
                          <React.Fragment key={index}>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor={`language-${index}`}
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Select Language
                              </label>
                              <select
                                className="form-control"
                                value={item.language}
                                onChange={(e) =>
                                  handleTitleTypeChange(
                                    index,
                                    "language",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select Language</option>
                                {language.map((lang) => (
                                  <option key={lang._id} value={lang.code}>
                                    {lang.name}
                                  </option>
                                ))}
                              </select>
                              {errors[`titles-${index}-language`] && (
                                <small className="text-danger">
                                  {errors[`titles-${index}-language`]}
                                </small>
                              )}
                            </Col>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor={`title-${index}`}
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Title
                              </label>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control me-2"
                                  value={item.title}
                                  onChange={(e) =>
                                    handleTitleTypeChange(
                                      index,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                />
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => removeTitleType(index)}
                                  disabled={formData.titles.length === 1}
                                >
                                  Remove
                                </Button>
                              </div>
                              {errors[`titles-${index}-title`] && (
                                <small className="text-danger">
                                  {errors[`titles-${index}-title`]}
                                </small>
                              )}
                            </Col>
                          </React.Fragment>
                        ))}
                        <div className="d-flex px-4">
                          <Button
                            className="mt-2 btn-sm"
                            onClick={addTitleType}
                            type="button"
                          >
                            Add More
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2">
                          <Button
                            className="text-uppercase fw-sbold w-100 btn-danger"
                            onClick={() => navigate("/tags")}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col md="7" className="my-2">
                          <Button
                          disabled={isDisabled}
                            type="submit"
                            className="text-uppercase fw-sbold w-100 btn-primary"
                          >
                            {isDisabled?"Submiting...":"Submit"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AddTags;

