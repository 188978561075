import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguage } from "../../../../store/language/actions";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate, useParams } from "react-router-dom";
import { editTag, viewTag } from "../../../../store/actions";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
const validationSchema = yup.object().shape({
  type: yup.string().required("Tag type is required"),
  name: yup.string().required("Name is required"),
  gender: yup.string().required("Gender is required"),
  titles: yup.array().of(
    yup.object().shape({
      language: yup.string().required("Language is required"),
      title: yup.string().required("Title is required"),
    })
  ),
});
const EditTags = () => {
  const { TAG, loading, totalCount } = useSelector((state) => state.Tag);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState([]);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      type: "",
      name: "",
      gender: "",
      titles: [{ language: "", title: "" }],
    },
  });
  useEffect(() => {
    if (!TAG?.data) return;
    reset({
      id: TAG.data._id,
      type: TAG.data.type || "",
      name: TAG.data.name || "",
      gender: TAG.data.gender || "",
      titles: TAG.data.titles?.map((item) => ({
        language: item?.language || "",
        title: item?.title || "",
      })) || [{ language: "", title: "" }],
    });
  }, [TAG, reset]);
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
            console.error(err);
          } else {
            setLanguage(res);
          }
        };
        dispatch(getAllLanguage({ callback }));
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
            console.error(err);
          } else {
            console.log(res, "fetchData");
          }
        };
        dispatch(viewTag({ data: id, callback }));
      } catch (error) {
        console.error("Error fetching Data:", error);
      }
    };
    fetchData();
  }, [id, dispatch]);
  const handleChange = (index, field, value) => {
    const updatedTitles = [...getValues("titles")];
    updatedTitles[index] = {
      ...updatedTitles[index],
      [field]: value,
    };
    setValue("titles", updatedTitles, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const addTitleType = () => {
    // Get the current titles and add a new empty object
    const updatedTitles = [...getValues("titles"), { language: "", title: "" }];
    
    // Update the form state and trigger re-render
    setValue("titles", updatedTitles, { shouldDirty: true, shouldValidate: true });
  };
  
  const removeTitleType = (index) => {
    const updatedTitles = getValues("titles").filter((_, i) => i !== index);
    setValue("titles", updatedTitles,{ shouldDirty: true, shouldValidate: true });
  };
  const onSubmit = (data) => {
    console.log(data, "data====<>");
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err.message);
          console.error("Error submitting:", err);
        } else {
          navigate("/tags");
          toast.success(res.message);
          reset({
            type: "",
            name: "",
            gender: "",
            titles: [{ language: "", title: "" }],
          });
        }
      };
      dispatch(editTag({ data, callback }));
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form className="mx-auto" onSubmit={handleSubmit(onSubmit)}>
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Edit tag:
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center">
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="type"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Tag Type
                          </label>
                          <Controller
                            control={control}
                            name="type"
                            render={({ field }) => (
                              <select
                                className="form-control"
                                {...field}
                                id="type"
                              >
                                <option value="">Select type</option>
                                <option value="tag">Tag</option>
                                <option value="personality">Personality</option>
                                <option value="hobby">Hobby</option>
                                <option value="cloths">Cloths</option>
                                <option value="background">Background</option>
                                <option value="occupation">Occupation</option>
                              </select>
                            )}
                          />
                          {errors.type && (
                            <p className="text-danger">{errors.type.message}</p>
                          )}
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="gender"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Gender
                          </label>
                          <Controller
                            control={control}
                            name="gender"
                            render={({ field }) => (
                              <select
                                className="form-control"
                                {...field}
                                id="gender"
                              >
                                <option value="">Select Gender</option>
                                <option value="all">All</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            )}
                          />
                          {errors.gender && (
                            <p className="text-danger">
                              {errors.gender.message}
                            </p>
                          )}
                        </Col>
                        <Col md="12" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Prompt Value
                          </label>
                          <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                {...field}
                                id="name"
                              />
                            )}
                          />
                          {errors.name && (
                            <p className="text-danger">{errors.name.message}</p>
                          )}
                        </Col>
                        {getValues("titles").map((item, index) => (
                          <React.Fragment key={index}>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor={`language-${index}`}
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Select Language
                              </label>
                              <select
                                className="form-control"
                                value={item.language}
                                name="language"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "language",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select Language</option>
                                {language?.map((lang) => (
                                  <option key={lang?._id} value={lang?.code}>
                                    {lang?.name}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor={`title-${index}`}
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Title
                              </label>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control me-2"
                                  value={item.title}
                                  name="title"
                                  onChange={(e) =>
                                    handleChange(index, "title", e.target.value)
                                  }
                                />
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => removeTitleType(index)}
                                  disabled={getValues("titles").length === 1}
                                >
                                  Remove
                                </Button>
                              </div>
                            </Col>
                          </React.Fragment>
                        ))}
                        <div className="d-flex px-4">
                          <Button
                            className="mt-2 btn-sm"
                            onClick={addTitleType}
                            type="button"
                          >
                            Add More
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2">
                          <Button
                            className="d-flex align-items-center justify-content-center commonBtn w-100 fw-sbold"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default EditTags;
