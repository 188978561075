import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { UserCharacterRevenue } from "../../../../../store/actions";
import TableLayout from "../../../../../components/TableLayout";
const ViewCharacterRevenue = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tokenData, setTokenData] = useState();
  const { id } = useParams();
  const [selectedFilters, setSelectedFilters] = useState({});
  const { REVENUELIST, loading, totalCount } = useSelector(
    (state) => state.RevenueList
  );
  const [filter, setFilter] = useState({
    userId: id,
    pageNo: currentPage,
    limit: pageSize,
  });
  const fetchData = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setTokenData(res);
          toast.success(res.message);
        }
      };
      dispatch(UserCharacterRevenue({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handleFilter = () => {
    const filterArray = Object.keys(selectedFilters).map((key) => ({
      fieldName: key,
      fieldValue: selectedFilters[key],
    }));
    setFilter((prev) => ({
      ...prev,
      filter: filterArray,
      pageNo: 1,
    }));
  };
  const clearFilter = () => {
    setSelectedFilters({});
    setFilter({ type: "personal", pageNo: 1, limit: pageSize });
  };
  useEffect(() => {
    if (!id) {
      return;
    }
    const timer = setTimeout(() => fetchData(), 500);
    return () => clearTimeout(timer);
  }, [filter, id]);
  const handlePageChange = (page) => {
    setFilter((prev) => ({ ...prev, pageNo: page.selected + 1 }));
  };
  const column = [
    {
      head: "Sr No",
      accessor: "",
      isComponent: true,
      component: (item, ind) => {
        const calculateRowIndex = (ind) =>
          (filter.pageNo - 1) * pageSize + (ind + 1);
        return (
          <span className="text-capitalize cursor-pointer">
            {calculateRowIndex(ind)}
          </span>
        );
      },
    },
    {
      head: "Character Image",
      accessor: "image",
      isComponent: true,
      component: (item, key) => {
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <div className="imgWrp flex-shrink-0">
                <img
                  src={item.image}
                  alt=""
                  style={{ height: 40, width: 40 }}
                  className="img-fluid object-fit-cover rounded-circle"
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      head: "Name",
      accessor: "name",
    },
    {
      head: "Gender",
      accessor: "gender",
    },
    // {
    //   head: "Image Token Rate",
    //   accessor: "imageTokenRate",
    // },
    {
      head: "Revenue",
      accessor: "totalRevenue",
      isComponent: true,
      component: (item, ind) => (
        <span className="text">
          {typeof item?.totalRevenue === "number"
            ? item?.totalRevenue?.toFixed(4)
            : "0.0000"}
        </span>
      ),
    },
    // {
    //   head: "Actions",
    //   accessor: "",
    //   isComponent: true,
    //   component: (item) => (
    //     <div className="ActnBtn d-flex align-items-center  gap-10">
    //       <Button
    //         onClick={() => handleViewUser(item._id)}
    //         className="p-0"
    //         variant="transparent"
    //         type="button"
    //       >
    //         <svg
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             d="M12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9ZM12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5Z"
    //             fill="#6F6F6F"
    //           />
    //         </svg>
    //       </Button>
    //     </div>
    //   ),
    // },
  ];
  console.log(REVENUELIST, "REVENUELIST");
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="ManageTeacher position-relative">
        <Container>
          <Row className="">
            <Col lg="12" className="my-2 ">
              <div className="d-flex align-items-center justify-content-between ">
                <h5 className="mb-0 py-3 fw-sbold text-capitalize">
                  User Character Revenue
                </h5>
                <p className="mb-0 py-2 fw-sbold text-capitalize card px-3">
                  Total Revenue: ${" "}
                  {REVENUELIST?.data?.total &&
                    REVENUELIST?.data?.total?.toFixed(4)}
                </p>
              </div>
            </Col>
            <Row className="py-1">
              <Col lg="6">
                <div className=" border-1 h-100 border rounded bg-white ">
                  <div className="py-2 px-2 border-bottom">
                    <div className="topHead pb-1">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        User Details
                      </h5>
                    </div>
                  </div>
                  <div className="my-0 border-bottom ">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">User Name :-</p>
                      <p>{REVENUELIST?.data?.user?.userName}</p>
                    </span>
                  </div>
                  <div className="my-0  border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">Email :-</p>
                      <p>{REVENUELIST?.data?.user?.email}</p>
                    </span>
                  </div>
                  <div className="my-0 border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">Phone Number :-</p>
                      <p>{REVENUELIST?.data?.user?.phoneNo}</p>
                    </span>
                  </div>
                  <div className="my-0 border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">Personal Character:-</p>
                      <p>{REVENUELIST?.data?.user?.personalCharacters}</p>
                    </span>
                  </div>
                  <div className="my-0 border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">Public Character:-</p>
                      <p>{REVENUELIST?.data?.user?.publicCharacters}</p>
                    </span>
                  </div>
                  <div className="my-0 ">
                    <span className="px-0 d-flex justify-content-between px-2  border-1 cardCstm">
                      <p className="fw-medium">Status :-</p>
                      <p
                        className="text-capitalize "
                        style={{ color: "green" }}
                      >
                        {REVENUELIST?.data?.user?.status}
                      </p>
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg="6" className="">
                <div className="border-1 h-100 border rounded bg-white ">
                  <div className="py-2 px-2 border-bottom">
                    <div className="topHead pb-1">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Account Details
                      </h5>
                    </div>
                  </div>
                  <div className="my-0 border-bottom ">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium"> Account holder Name :-</p>
                      <p>{REVENUELIST?.data?.user?.accountDetails?.name}</p>
                    </span>
                  </div>
                  <div className="my-0  border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">Country Name :-</p>

                      <p>{REVENUELIST?.data?.user?.accountDetails?.country}</p>
                    </span>
                  </div>
                  <div className="my-0 border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">Address :-</p>
                      <p>{REVENUELIST?.data?.user?.accountDetails?.address}</p>
                    </span>
                  </div>
                  <div className="my-0 border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">SwiftCocde :-</p>
                      <p>
                        {REVENUELIST?.data?.user?.accountDetails?.swiftCode}
                      </p>
                    </span>
                  </div>
                  <div className="my-0 border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">Iban No :-</p>
                      <p>{REVENUELIST?.data?.user?.accountDetails?.iban}</p>
                    </span>
                  </div>
                  <div className="my-0 border-bottom">
                    <span className="px-0 d-flex justify-content-between px-2 border-bottom-2 border-1 cardCstm">
                      <p className="fw-medium">Wallet Address :-</p>
                      <p>{REVENUELIST?.data?.user?.accountDetails?.wallet}</p>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm  rounded bg-white">
                {!loading && REVENUELIST?.data?.characters?.length > 0 ? (
                  <>
                    <TableLayout
                      data={REVENUELIST?.data?.characters}
                      column={column}
                      totalCount={totalCount}
                      limit={filter.limit}
                      handlePageChange={handlePageChange}
                      pageNo={filter.pageNo}
                    />
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <p>No Data Found</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ViewCharacterRevenue;
