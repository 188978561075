import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";
const initialState = {
  loading: false,
  REVENUELIST: [],
  totalCount: 0,
};
const slice = createSlice({
  name: "REVENUELIST",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.RevenueList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.RevenueList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      REVENUELIST: payload || [],
      totalCount: payload.totalcount || 0,
    }));
    builder.addCase(Act.RevenueList.rejected, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload || "Unknown error",
      REVENUELIST: [],
      totalCount: 0,
    }));
    // user character  revuenue
    builder.addCase(Act.UserCharacterRevenue.pending, (state, action) => ({
        ...state,
        loading: true,
      }));
      builder.addCase(Act.UserCharacterRevenue.fulfilled, (state, { payload }) => ({
        ...state,
        loading: false,
        REVENUELIST: payload || [],
        totalCount: payload.totalcount || 0,
      }));
      builder.addCase(Act.UserCharacterRevenue.rejected, (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload || "Unknown error",
        REVENUELIST: [],
        totalCount: 0,
      }));

  },
});

export default slice;
