import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, del } from "../../helpers/api_helper";
import slice from "./slice";
import * as URL from "../../helpers/url_helper";
import { toast } from "react-toastify";

export const TokenRevenueList = createAsyncThunk(
  "TokenRevenueList",
  async ({ data, callback }, Thunk) => {
    try {
      const queryParams = new URLSearchParams(data).toString();
      const url = `${URL.TOKENREVEUNE}?${queryParams}`;
      let response = await get(url);
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);

export const ImagesToken = createAsyncThunk(
    "ImagesToken",
    async ({ data, callback }, Thunk) => {
      try {
        const { id, ...restData } = data;
        const url = `${URL.IMAGESTOKEN}/${id}/users`;
        let response = await post(url, restData);
        callback && callback(null, response);
        return response;
      } catch (error) {
        console.log(error);
        return Thunk.rejectWithValue(error);
      }
    }
  );
  