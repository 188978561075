import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TableLayout from "../TableLayout";
import { useDispatch, useSelector } from "react-redux";
import { ImagesToken } from "../../store/actions";
import { toast } from "react-toastify";
const ImagesSpendToken = ({ id, type }) => {
  const [data, setData] = useState();
  const { PAYMENTSLISTDATA, loading, totalCount } = useSelector(
    (state) => state.PaymentData
  );
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    id: id,
    type: type,
    limit: 10,
    pageNo: 1,
  });
  const getUserPrivate = () => {
    const callback = (err, res) => {
      if (err) {
        console.log(err);
      } else {
        toast.success(res);
        setData(res);
      }
    };
    dispatch(ImagesToken({ data: filter, callback }));
  };
  useEffect(() => {
    getUserPrivate();
  }, []);
  const handlePageChange = (page) => {
    setFilter((prev) => ({ ...prev, pageNo: page.selected + 1 }));
  };
  const column = [
    {
      head: "Name",
      accessor: "name",
      component: (item, key) => {
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <div className="content">
                <p className="m-0 text-muted">{item?.name}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      head: "User Name",
      accessor: "userName",
      component: (item, key) => {
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <div className="content">
                <p className="m-0 text-muted">{item?.userName}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      head: "Email",
      accessor: "email",
      isComponent: true,
      component: (item, key) => {
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <div className="content">
                <p className="m-0 text-muted">{item?.email}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      head: "Token Spent",
      accessor: "tokenSpent",
      isComponent: true,
      component: (item, key) => {
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <div className="content">
                <p className="m-0 text-muted">{item?.tokenSpent}</p>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col lg="12" className="my-2">
          <div className="cardCstm  h-100 rounded  bg-white">
            <div className="cardHead m-0 pb-2">
              <h6 className="m-0 fw-bolder">Images Spend Token List</h6>
            </div>
            {!loading && data?.data?.length > 0 ? (
              <>
                <TableLayout
                  data={data?.data}
                  column={column}
                  totalCount={data?.totalcount}
                  limit={filter.limit}
                  handlePageChange={handlePageChange}
                  pageNo={filter.pageNo}
                  status={"status"}
                />
              </>
            ) : (
              <div className="d-flex justify-content-center p-2">
                <p className="m-0">No Data Found</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ImagesSpendToken;
