import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { getCharacterDetails } from "../../../../../store/actions";
import PublicCharacterView from "../../../../../components/PublicCharcter";
import PersonalCharacterView from "../../../../../components/PersonalCharacter";
import ImagesSpendToken from "../../../../../components/ImagesSpendToken";
import MessagesSpendToken from "../../../../../components/MessagesSpendToken";
const PersonalView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState();
  const { loading } = useSelector((state) => state.User);
  const getCharacterViews = () => {
    const callback = (err, res) => {
      if (err) {
        console.log(err);
      } else {
        setUserDetails(res);
      }
    };
    dispatch(getCharacterDetails({ data: id, callback }));
  };
  useEffect(() => {
    getCharacterViews();
  }, []);
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-4">
        <Container>
          <Row className="g-3">
            <Col md="4" className="my-2">
              <div className="flex-grow-1 d-flex flex-column">
                <div className="cardCstm p-3 border rounded-4 bg-light shadow-sm h-100">
                  <div className="top pt-2 pb-3 border-bottom border-secondary">
                    <h5 className="m-0 fw-bold themeClr">
                      {userDetails?.name}
                      <span
                        className="text-muted fw-sbold text-capitalize"
                        style={{ fontSize: 12 }}
                      >
                        ({userDetails?.type})
                      </span>
                    </h5>
                  </div>
                  <div className="imgWrp justify-content-center mt-3">
                    <img
                      src={userDetails?.image || "placeholder.png"}
                      alt=""
                      style={{ height: "330px" }}
                      className=" w-100 h-100 object-fit-cover rounded"
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="8" className="my-2">
              <div className="content mt-0 cardCstm border rounded-4 bg-light shadow-sm px-4 py-2">
                <div className="d-flex justify-content-between border-bottom">
                  <h6 className="m-0 fw-bold themeClr">Age:</h6>
                  <span>{userDetails?.age}</span>
                </div>
                <ul className="list-unstyled" style={{ fontSize: 12 }}>
                  <li className="d-flex justify-content-between border-bottom">
                    <p className="themeClr m-0 fw-bold">Date Of Birth:</p>
                    <p className="py-1 m-0">{userDetails?.dob}</p>
                  </li>
                  <li className="d-flex justify-content-between border-bottom">
                    <p className="themeClr m-0 fw-bold">Accent:</p>
                    <p className="py-1 m-0">{userDetails?.accent}</p>
                  </li>
                  <li className="d-flex justify-content-between border-bottom">
                    <p className="themeClr m-0 fw-bold">Style:</p>
                    <p className="py-1 m-0">{userDetails?.style}</p>
                  </li>
                  <li className="d-flex justify-content-between border-bottom">
                    <p className="themeClr m-0 fw-bold">Breast:</p>
                    <p className="py-1 m-0">{userDetails?.breast}</p>
                  </li>
                  <li className="d-flex justify-content-between border-bottom">
                    <p className="themeClr m-0 fw-bold">Butt:</p>
                    <p className="py-1 m-0">{userDetails?.butt}</p>
                  </li>
                  <li className="d-flex justify-content-between border-bottom ">
                    <p className="themeClr m-0 fw-bold">Ethnicity:</p>
                    <p className="py-1 m-0">{userDetails?.ethnicity}</p>
                  </li>
                  <li className="border-bottom ">
                    <p className="themeClr m-0 fw-bold">Introduction:</p>
                    <p className="py-1 m-0">{userDetails?.introduction}</p>
                  </li>
                </ul>
                <div className="border-bottom">
                  <h5 className="m-0 fw-bold" style={{ color: "#2E3A59" }}>
                    Other Details
                  </h5>
                </div>
                <div className="content ">
                  <div className="d-flex justify-content-between border-bottom py-2">
                    <p className="fw-bold m-0 themeClr">Hobby:</p>
                    <p className="m-0">
                      {userDetails?.hobby
                        ?.map((item) => item.trim())
                        .join(", ")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between border-bottom py-2">
                    <p className="fw-bold m-0 themeClr">Personality:</p>
                    <p className="m-0">
                      {userDetails?.personality
                        ?.map((item) => item.trim())
                        .join(", ")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between border-bottom py-2">
                    <p className="fw-bold m-0 themeClr">Tags:</p>
                    <p className="m-0">
                      {userDetails?.tag?.map((item) => item.trim()).join(", ")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between border-bottom py-2">
                    <p className="fw-bold m-0 themeClr">Character Type:</p>
                    <p className="m-0">{userDetails?.type}</p>
                  </div>
                  {/* <div className="d-flex justify-content-between border-bottom py-2">
                      <p className="fw-bold m-0 themeClr">Status:</p>
                      <p className="m-0">{userDetails?.status}</p>
                    </div> */}
                  {/* <div className="border-bottom py-2">
                      <p className="fw-bold m-0 themeClr">Image Prompt:</p>
                      <span className="d-flex  flex-wrap ">
                        {userDetails?.imagePrompt}
                      </span>
                    </div>
                    <div className=" border-bottom py-2">
                      <p className="fw-bold m-0 themeClr">Chat Prompt:</p>
                      <span className="d-flex flex-wrap ">
                        {userDetails?.chatPrompt}
                      </span>
                    </div> */}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col lg="12" className="my-2">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Dashboard"
              >
                <Nav variant="pills" className="btnPillsTab row mx-0">
                  <Nav.Item className="col-lg-3 col-sm-6 my-2 px-0">
                    <Nav.Link
                      className="d-flex align-items-center justify-content-center border rounded-0"
                      eventKey="Dashboard"
                    >
                      Images Spend Token
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="col-lg-3 col-sm-6 my-2 px-0">
                    <Nav.Link
                      className="d-flex align-items-center justify-content-center border rounded-0"
                      eventKey="Programs"
                    >
                      Messages Spend Token 
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="pt-4">
                  <Tab.Pane eventKey="Dashboard">
                    <ImagesSpendToken id={id}  type="words"/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Programs">
                    <MessagesSpendToken id={id} type="image"/>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PersonalView;
