import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguage } from "../../../../store/language/actions";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate, useParams } from "react-router-dom";
import { editHome, uploadFile, viewHome } from "../../../../store/actions";
const EditHome = () => {
  const { HOME, loading, totalCount } = useSelector((state) => state.Home);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type: "",
    language: "",
    title: "",
    description: "",
    backgroundImage: "",
    images: [],
    buttonPersonal: "",
    buttonPublic: "",
  });
  useEffect(() => {
    if (!HOME?.data) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      id: HOME.data._id,
      type: HOME.data.type,
      language: HOME.data.language,
      title: HOME.data.title,
      description: HOME.data.description,
      backgroundImage: HOME.data.backgroundImage,
      images: HOME?.data?.images || [],
      buttonPersonal: HOME.data.buttonPersonal,
      buttonPublic: HOME.data.buttonPublic,
    }));
  }, [HOME]);
  const fetchLanguages = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          console.error(err);
        } else {
          setLanguage(res);
        }
      };
      dispatch(getAllLanguage({ callback }));
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchData = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          console.error(err);
        } else {
          console.log(res, "fetchData");
        }
      };
      dispatch(viewHome({ data: id, callback }));
    } catch (error) {
      console.error("Error fetching Data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleFiles = (_files) => {
    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB
    const files = Array.from(_files).filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    if (files.length < _files.length) {
      toast.error("Max file upload size is 50MB");
      return;
    }
    setFormData((prev) => ({
      ...prev,
      backgroundImage: URL.createObjectURL(files[0]),
    }));
    setIsLoading(true);
    const callback = (err, res) => {
      if (err) {
        console.error("File upload error:", err);
        toast.error("Failed to upload file");
      } else {
        console.log("Response with URL:", res);
        toast.success("File uploaded successfully");
        // Update state with the API response comes
        setFormData((prev) => ({
          ...prev,
          backgroundImage: res?.data?.url,
        }));
      }
      setIsLoading(false);
      // Hide loading state after response comes
      // setFormData((prev) => ({
      //   ...prev,
      //   isLoading: false,
      // }));
    };
    // Dispatch file upload
    dispatch(uploadFile({ data: files[0], callback }));
  };

  const handleMultipleFiles = (_files) => {
    const files = Array.from(_files).filter((file) => file.size < 10e6);
    if (files.length < _files.length) {
      toast.error("Max file upload size is 10MB per image");
      return;
    }
    files.forEach((file) => {
      const callback = (err, res) => {
        if (err) {
          console.error("File upload error:", err);
          toast.error("Failed to upload file");
        } else {
          toast.success("File uploaded successfully");
          setFormData((prev) => ({
            ...prev,
            images: [...prev.images, res?.data?.url],
          }));
        }
      };
      dispatch(uploadFile({ data: file, callback }));
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "forndata =======<>");
    try {
      setIsLoading(true);
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          setIsLoading(false);
          console.error("Error submitting:", err);
        } else {
          toast.success("Updated Successfully");
          navigate("/homepage");
          setFormData({
            type: "",
            language: "",
            title: "",
            description: "",
            backgroundImage: "",
            images: [""],
            buttonPersonal: "",
            buttonPublic: "",
          });
        }
      };
      dispatch(editHome({ data: formData, callback }));
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form className="mx-auto" onSubmit={handleSubmit}>
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Edit Content:
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center">
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="ModalType"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Type
                          </label>
                          <select
                            className="form-control"
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                          >
                            <option value="">Select Modal type</option>
                            <option value="home">Home</option>
                            <option value="footer">Footer</option>
                            <option value="personal">Personal</option>
                            <option value="public">Public</option>
                            <option value="login">Login</option>
                            <option value="signup">Signup</option>
                            <option value="token">Token Content</option>
                          </select>
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor={`language`}
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Select Language
                          </label>
                          <select
                            className="form-control"
                            name="language"
                            value={formData.language}
                            onChange={handleChange}
                          >
                            <option value="">Select Language</option>
                            {language.map((lang) => (
                              <option key={lang._id} value={lang._id}>
                                {lang.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </Col>
                        {!["footer", "personal", "login", "signup","token"].includes(
                          formData.type
                        ) && (
                          <>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor="name"
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Public Button Content
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="buttonPublic"
                                value={formData.buttonPublic}
                                onChange={handleChange}
                              />
                            </Col>
                          </>
                        )}
                        {!["footer", "public", "login", "signup","token"].includes(
                          formData.type
                        ) && (
                          <>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor="name"
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Personal Button Content
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="buttonPersonal"
                                value={formData.buttonPersonal}
                                onChange={handleChange}
                              />
                            </Col>
                          </>
                        )}

                        <Col md="6" className="my-2">
                          <label
                            htmlFor="Image"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Background Image
                          </label>
                          {isLoading ? (
                            <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                              <li className="d-flex align-items-center gap-2 position-relative">
                                <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                  <span
                                    className="spinner-border text-primary"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="ms-2 text-primary">
                                    Uploading...
                                  </span>
                                </div>
                              </li>
                            </ul>
                          ) : (
                            <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                              {formData?.backgroundImage &&
                              typeof formData.backgroundImage === "string" ? (
                                <li className="d-flex align-items-center gap-2 position-relative">
                                  <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                    <img
                                      className="rounded-4"
                                      src={formData.backgroundImage}
                                      alt="Selected Icon"
                                      width={100}
                                      height={100}
                                    />
                                    <Button
                                      variant="danger"
                                      className="btn-sm border-0 p-0 fw-sbold d-flex align-items-center justify-content-center position-absolute rounded-circle"
                                      style={{
                                        top: 5,
                                        right: 5,
                                        fontSize: 8,
                                        height: 12,
                                        width: 12,
                                      }}
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          backgroundImage: "",
                                        })
                                      }
                                    >
                                      X
                                    </Button>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="image"
                                    onChange={(e) =>
                                      handleFiles(e.target.files)
                                    }
                                  />
                                </li>
                              )}
                            </ul>
                          )}
                        </Col>
                        {formData.type == "footer" && (
                          <>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor="images"
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Images
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                name="images"
                                multiple
                                onChange={(e) =>
                                  handleMultipleFiles(e.target.files)
                                }
                              />
                              <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                                {formData.images.map((image, index) => (
                                  <li
                                    key={index}
                                    className="d-flex align-items-center gap-2 position-relative"
                                  >
                                    <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                      <img
                                        className="rounded-4"
                                        src={image}
                                        alt={`Uploaded ${index}`}
                                        width={100}
                                        height={100}
                                      />
                                      <Button
                                        variant="danger"
                                        className="btn-sm border-0 p-0 fw-sbold d-flex align-items-center justify-content-center position-absolute rounded-circle"
                                        style={{
                                          top: 5,
                                          right: 5,
                                          fontSize: 8,
                                          height: 12,
                                          width: 12,
                                        }}
                                        onClick={() => handleRemoveImage(index)}
                                      >
                                        X
                                      </Button>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </div>

                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2">
                          <Button
                            className="d-flex align-items-center justify-content-center commonBtn w-100 fw-sbold"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default EditHome;
