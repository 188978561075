import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguage } from "../../../../store/language/actions";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate } from "react-router-dom";
import { AddContent, uploadFile } from "../../../../store/actions";
const AddHome = () => {
  const { loading } = useSelector((state) => state.Subscription);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type: "",
    language: "",
    title: "",
    description: "",
    backgroundImage: "",
    images: [],
    buttonPersonal: "",
    buttonPublic: "",
  });
  const [errors, setErrors] = useState({
    type: "",
    language: "",
    title: "",
    description: "",
    backgroundImage: "",
    buttonPersonal: "",
    buttonPublic: "",
  });
  const fetchLanguages = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          console.error(err);
        } else {
          setLanguage(res);
        }
      };
      dispatch(getAllLanguage({ callback }));
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };
  useEffect(() => {
    fetchLanguages();
  }, []);
  const validateField = (name, value, formData) => {
    switch (name) {
      case "type":
        return value ? "" : "Type is required.";
      case "language":
        return value ? "" : "Language is required.";
      case "title":
        return value ? "" : "Title is required.";
      case "description":
        return value ? "" : "Description is required.";
      case "buttonPersonal":
        if (
          !["footer", "public", "login", "signup", "token"].includes(
            formData?.type
          )
        ) {
          return value ? "" : "Personal button text is required.";
        }
        return "";
      case "buttonPublic":
        if (
          !["footer", "personal", "login", "signup", "token"].includes(
            formData?.type
          )
        ) {
          return value ? "" : "Public button text is required.";
        }
        return "";
      case "backgroundImage":
        return value ? "" : "Background image is required.";
      default:
        return "";
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const errorMessage = validateField(name, value);
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({
      ...errors,
      [name]: errorMessage,
    });
  };
  const handleFiles = (_files) => {
    const MAX_FILE_SIZE = 50 * 1024 * 1024;
    const files = Array.from(_files).filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    if (files.length < _files.length) {
      toast.error("Max file upload size is 50MB");
      return;
    }
    setFormData((prev) => ({
      ...prev,
      backgroundImage: URL.createObjectURL(files[0]),
    }));
    setIsLoading(true);
    const callback = (err, res) => {
      if (err) {
        console.error("File upload error:", err);
        toast.error("Failed to upload file");
      } else {
        console.log("Response with URL:", res);
        toast.success("File uploaded successfully");
        setFormData((prev) => ({
          ...prev,
          backgroundImage: res?.data?.url,
        }));
      }
      setIsLoading(false);
    };
    dispatch(uploadFile({ data: files[0], callback }));
  };
  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };
  const handleMultipleFiles = (_files) => {
    const files = Array.from(_files).filter((file) => file.size < 10e6);
    if (files.length < _files.length) {
      toast.error("Max file upload size is 10MB per image");
      return;
    }
    files.forEach((file) => {
      const callback = (err, res) => {
        if (err) {
          console.error("File upload error:", err);
          toast.error("Failed to upload file");
        } else {
          toast.success("File uploaded successfully");
          setFormData((prev) => ({
            ...prev,
            images: [...prev.images, res?.data?.url],
          }));
        }
      };
      dispatch(uploadFile({ data: file, callback }));
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (
        field === "buttonPersonal" &&
        ["footer", "public", "login", "signup", "token"].includes(formData?.type)
      ) {
        return;
      }
      if (
        field === "buttonPublic" &&
        ["footer", "personal", "login", "signup", "token"].includes(
          formData?.type
        )
      ) {
        return;
      }
      const error = validateField(field, formData[field], formData);
      if (error) {
        isValid = false;
      }
      newErrors[field] = error;
    });
    setErrors(newErrors);
    if (isValid) {
      try {
        const callback = (err, res) => {
          if (err) {
            toast.error(err);
            console.error("Error submitting:", err);
          } else {
            toast.success("Added Successfully");
            navigate("/homepage");
            setFormData({
              type: "",
              language: "",
              title: "",
              description: "",
              backgroundImage: "",
              images: [""],
              buttonPersonal: "",
              buttonPublic: "",
            });
          }
        };
        dispatch(AddContent({ data: formData, callback }));
      } catch (error) {
        console.error("Error during submission:", error);
      }
    }
  };
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form className="mx-auto" onSubmit={handleSubmit}>
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Add Content :
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center">
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="ModalType"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Type
                          </label>
                          <select
                            className="form-control"
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                          >
                            <option value="">Select type</option>
                            <option value="home">Home</option>
                            <option value="footer">Footer</option>
                            <option value="personal">Personal</option>
                            <option value="public">Public</option>
                            <option value="login">Login</option>
                            <option value="signup">Signup</option>
                            <option value="token">Token Content</option>
                          </select>
                          {errors.type && (
                            <div className="text-danger">{errors.type}</div>
                          )}
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="language"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Select Language
                          </label>
                          <select
                            id="language"
                            name="language"
                            className="form-control"
                            value={formData.language}
                            onChange={handleChange}
                          >
                            <option value="">Select Language</option>
                            {language.map((lang) => (
                              <option key={lang._id} value={lang._id}>
                                {lang.name}
                              </option>
                            ))}
                          </select>
                          {errors.language && (
                            <div className="text-danger">{errors.language}</div>
                          )}
                        </Col>

                        <Col md="6" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                          />
                          {errors.title && (
                            <div className="text-danger">{errors.title}</div>
                          )}
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Description
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                          />
                          {errors.description && (
                            <div className="text-danger">
                              {errors.description}
                            </div>
                          )}
                        </Col>
                        {![
                          "footer",
                          "personal",
                          "login",
                          "signup",
                          "token",
                        ].includes(formData.type) && (
                          <Col md="6" className="my-2">
                            <label
                              htmlFor="name"
                              className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                            >
                              Public Button Content
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="buttonPublic"
                              value={formData.buttonPublic}
                              onChange={handleChange}
                            />
                            {errors.buttonPublic && (
                              <div className="text-danger">
                                {errors.buttonPublic}
                              </div>
                            )}
                          </Col>
                        )}
                        {![
                          "footer",
                          "public",
                          "login",
                          "signup",
                          "token",
                        ].includes(formData.type) && (
                          <Col md="6" className="my-2">
                            <label
                              htmlFor="name"
                              className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                            >
                              Personal Button Content
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="buttonPersonal"
                              value={formData.buttonPersonal}
                              onChange={handleChange}
                            />
                            {errors.buttonPersonal && (
                              <div className="text-danger">
                                {errors.buttonPersonal}
                              </div>
                            )}
                          </Col>
                        )}
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="Image"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Banner Image
                          </label>
                          {isLoading ? (
                            <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                              <li className="d-flex align-items-center gap-2 position-relative">
                                <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                  <span
                                    className="spinner-border text-primary"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="ms-2 text-primary">
                                    Uploading...
                                  </span>
                                </div>
                              </li>
                            </ul>
                          ) : (
                            <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                              {formData?.backgroundImage &&
                              typeof formData.backgroundImage === "string" ? (
                                <li className="d-flex align-items-center gap-2 position-relative">
                                  <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                    <img
                                      className="rounded-4"
                                      src={formData.backgroundImage}
                                      alt="Selected Icon"
                                      width={100}
                                      height={100}
                                    />
                                    <Button
                                      variant="danger"
                                      className="btn-sm border-0 p-0 fw-sbold d-flex align-items-center justify-content-center position-absolute rounded-circle"
                                      style={{
                                        top: 5,
                                        right: 5,
                                        fontSize: 8,
                                        height: 12,
                                        width: 12,
                                      }}
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          backgroundImage: "",
                                        })
                                      }
                                    >
                                      X
                                    </Button>
                                  </div>
                                </li>
                              ) : (
                                <input
                                  type="file"
                                  className="form-control"
                                  name="image"
                                  onChange={(e) => handleFiles(e.target.files)}
                                />
                              )}
                            </ul>
                          )}
                          {errors.image && (
                            <div className="text-danger">{errors.image}</div>
                          )}
                        </Col>
                        {formData.type == "footer" &&
                          formData.type !== "login" &&
                          formData.type !== "signup" && (
                            <>
                              <Col md="6" className="my-2">
                                <label
                                  htmlFor="images"
                                  className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                                >
                                  Images
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="images"
                                  multiple
                                  onChange={(e) =>
                                    handleMultipleFiles(e.target.files)
                                  }
                                />
                                <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                                  {formData.images.map((image, index) => (
                                    <li
                                      key={index}
                                      className="d-flex align-items-center gap-2 position-relative"
                                    >
                                      <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                        <img
                                          className="rounded-4"
                                          src={image}
                                          alt={`Uploaded ${index}`}
                                          width={100}
                                          height={100}
                                        />
                                        <Button
                                          variant="danger"
                                          className="btn-sm border-0 p-0 fw-sbold d-flex align-items-center justify-content-center position-absolute rounded-circle"
                                          style={{
                                            top: 5,
                                            right: 5,
                                            fontSize: 8,
                                            height: 12,
                                            width: 12,
                                          }}
                                          onClick={() =>
                                            handleRemoveImage(index)
                                          }
                                        >
                                          X
                                        </Button>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </Col>
                            </>
                          )}
                        {errors.images && (
                          <div className="text-danger">{errors.images}</div>
                        )}
                      </Row>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2">
                          <Button
                            className="d-flex align-items-center justify-content-center commonBtn w-100 fw-sbold"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AddHome;
