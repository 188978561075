import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSettings, updateSettings } from "../../../store/settings/actions";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
const Setting = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [settingData, setSettingData] = useState();
  const { settings, loading } = useSelector((state) => state.Setting);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [formData, setFormData] = useState({
    wordsPerToken: "",
    imageTokenRate: "",
    allowCustomPricing: "",
    minTokenPrice: "",
    maxTokenPrice: "",
    tokenRevenueShare: "",
    imageGenerationUrl:""
  });
  const fetchData = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          setSettingData(res);
        }
      };
      dispatch(getSettings({ callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (settingData) {
      setFormData({
        wordsPerToken: settingData?.tokenSetting?.wordsPerToken,
        imageTokenRate: settingData?.tokenSetting?.imageTokenRate,
        allowCustomPricing: settingData?.tokenSetting?.allowCustomPricing,
        minTokenPrice: settingData?.tokenSetting?.minTokenPrice,
        maxTokenPrice: settingData?.tokenSetting?.maxTokenPrice,
        tokenRevenueShare: settingData?.tokenSetting?.tokenRevenueShare,
        imageGenerationUrl:settingData?.tokenSetting?.imageGenerationUrl
      });
    }
  }, [settingData]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleSubmit_ = (info) => {
    try {
      let query = {
        tokenSetting: {
          wordsPerToken: info?.wordsPerToken,
          imageTokenRate: info?.imageTokenRate,
          allowCustomPricing: info?.allowCustomPricing,
          minTokenPrice: info?.minTokenPrice,
          maxTokenPrice: info?.maxTokenPrice,
          tokenRevenueShare: info?.tokenRevenueShare,
          imageGenerationUrl:info?.imageGenerationUrl
        },
      };
      const callBack = (err, res) => {
        if (err) {
          console.error(err, "error message");
        } else {
          console.log(res);
          toast.success("Plan Updated Successfully");
        }
      };
      dispatch(updateSettings({ data: query, callback: callBack }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form
                  className="mx-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit_(formData);
                  }}
                >
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Basic Settings
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center align-items-center">
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Words Per Token
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="wordsPerToken"
                            value={formData.wordsPerToken}
                            min="0"
                            onChange={handleChange}
                          />
                        </Col>

                        <Col md="6" className="my-2">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Token Per Image
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="imageTokenRate"
                            value={formData.imageTokenRate}
                            min="0"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="12" className="my-2">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Image Generation Url
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="imageGenerationUrl"
                            value={formData.imageGenerationUrl}
                            min="0"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="12" className="my-2">
                          <label
                            htmlFor="words"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Token Revenue Share
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              name="tokenRevenueShare"
                              value={formData.tokenRevenueShare}
                              min="0.00"
                              step="0.01"
                              onChange={handleChange}
                            />
                            {/* <span className="input-group-text">%</span> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2 ">
                          <Button
                            className="d-flex align-items-center justify-content-center commonBtn w-100 fw-sbold"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Setting;
