import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AddUsers } from "../../../../store/actions";
const AddUser = () => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [statePhone, setStatePhone] = useState();
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    countryCode: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };
  const validateFormData = () => {
    const newErrors = {};
    if (!formData.lastName) newErrors.lastName = "LastName is required";
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    // if (!formData.countryCode)
    //   newErrors.countryCode = "Country Code is required";
    // if (!formData.phoneNo) newErrors.phoneNo = "Phone Number is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.confirmPassword)
      newErrors.confirmPassword = "Confirm Passsword is required";
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      return;
    }
    setIsDisabled(true);
    const payload = {
      ...formData,
      countryCode: statePhone?.countryCode,
      phoneNo: statePhone?.phoneNo,
    };
    console.log("submission called");
    const callback = (err, res) => {
      if (err) {
        // console.log(err,"err---------77777777777")
        // toast.error(err?.message);
        setIsDisabled(false);
      } else {
        toast.success(res.message);
        navigate("/user");
        setIsDisabled(false);
        setFormData({
          name: "",
          lastName: "",
          email: "",
          countryCode: "",
          phoneNo: "",
          password: "",
          confirmPassword: "",
        });
      }
    };
    dispatch(AddUsers({ data: payload, callback }));
  };
  console.log(formData, "formdata======>");
  console.log(statePhone, "statePhone");
  return (
    <>
      {/* {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )} */}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form className="mx-auto" onSubmit={handleSubmit}>
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Add User:
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center">
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <small className="text-danger">{errors.name}</small>
                          )}
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="lName"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                          {errors.lastName && (
                            <small className="text-danger">
                              {errors.lastName}
                            </small>
                          )}
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="Email"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="countryCode"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Country Code
                          </label>
                          <PhoneInput
                            country={"us"}
                            value={formData.phoneNo}
                            onChange={(value, country) => {
                              const phoneNumberOnly = value.replace(
                                `+${country.dialCode}`,
                                ""
                              );
                              setStatePhone({
                                phoneNo: phoneNumberOnly,
                                countryCode: country.dialCode,
                              });
                            }}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: true,
                            }}
                            containerClass="react-tel-input"
                            inputClass="form-control"
                            buttonClass="flag-dropdown"
                          />
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="password"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                          {errors.password && (
                            <small className="text-danger">
                              {errors.password}
                            </small>
                          )}
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="ConfirmPassword"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                          />
                          {errors.confirmPassword && (
                            <small className="text-danger">
                              {errors.confirmPassword}
                            </small>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2">
                          <Button
                            className="text-uppercase fw-sbold w-100 btn-danger"
                            onClick={() => navigate("/user")}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col md="7" className="my-2">
                          <Button
                            disabled={isDisabled}
                            type="submit"
                            className="text-uppercase fw-sbold w-100 btn-primary"
                          >
                            {/* {isDisabled ? "Submiting..." : "Submit"} */}
                            submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AddUser;
