import { combineReducers } from "@reduxjs/toolkit";
//reducers
import authReducer from "./auth/reducers";
import subscriptionReducer from "./subscription/reducers";
import profileReducer from "./profile/reducers";
import characterReducer from "./characterSetting/reducers";
import languageReducer from "./language/reducers";
import tokenReducer from "./tokenpackage/reducers";
import faqReducer from "./faq/reducers";
import pageReducer from "./pages/reducers";
import userReducer from "./users/reducers";
import settingReducer from "./settings/reducer";
import transactionReducer from "./transaction/reducers";
import tagReducer from "./manageTag/reducer";
import homeReducer from "./homePage/reducer";
import characterListReducer from "./character/reducers";
import faqCategoryListReducer from "./faqCategory/reducers"
import RavenueListReducer from "./revenue/reducers"
import PaymentDataReducer from "./payments/reducers"
const rootReducer = combineReducers({
  Auth: authReducer,
  Profile: profileReducer,
  Character: characterReducer,
  Language: languageReducer,
  Token: tokenReducer,
  Faq: faqReducer,
  Pages: pageReducer,
  User: userReducer,
  Subscription: subscriptionReducer,
  Setting: settingReducer,
  Transaction: transactionReducer,
  Tag: tagReducer,
  Home: homeReducer,
  CharacterList: characterListReducer,
  FaqCategoryList:faqCategoryListReducer,
  RevenueList:RavenueListReducer,
  PaymentData:PaymentDataReducer
});

export default rootReducer;
