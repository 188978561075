import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import TableLayout from "../../../../components/TableLayout";
import { TokenRevenueList } from "../../../../store/payments/actions";
const SubscriptionPayment = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tokenData, setTokenData] = useState();
  const [status, setStatus] = useState("All");
  const [type, setType] = useState("Subscription");
  const capitalize = (str) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  const [selectedFilters, setSelectedFilters] = useState({});
  const { PAYMENTSLISTDATA, loading, totalCount } = useSelector(
    (state) => state.PaymentData
  );
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    status: status,
    type: type,
    pageNo: currentPage,
    limit: pageSize,
  });
  const fetchData = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setTokenData(res);
        }
      };
      dispatch(TokenRevenueList({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handleSelectChange = (fieldName, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };
  const handleFilter = () => {
    setFilter((prev) => ({
      ...prev,
      status: status,
    }));
  };
  const clearFilter = () => {
    setFilter({ pageNo: 1, limit: pageSize, type: type });
  };
  useEffect(() => {
    const timer = setTimeout(() => fetchData(), 500);
    return () => clearTimeout(timer);
  }, [filter]);

  const deleteHandler = (id) => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          fetchData();
          toast.success(res.message);
        }
      };
      //   dispatch(
      //     deleteREVENUELIST({
      //       data: id,
      //       callback,
      //     })
      //   );
    } catch (error) {
      console.log(error, "<===err");
    }
  };
  const handleViewUser = (id) => {
    navigate(`/persional-character/${id}`);
  };
  const handlePageChange = (page) => {
    setFilter((prev) => ({ ...prev, pageNo: page.selected + 1 }));
  };

  console.log(tokenData, "tokenData========");

  const column = [
    {
      head: "Sr No",
      accessor: "",
      isComponent: true,
      component: (item, ind) => {
        const calculateRowIndex = (ind) =>
          (filter.pageNo - 1) * pageSize + (ind + 1);
        return (
          <span className="text-capitalize cursor-pointer">
            {calculateRowIndex(ind)}
          </span>
        );
      },
    },
    {
      head: "User Name",
      accessor: "userName",
      isComponent: true,
      component: (item, ind) => (
        <span className="text">{capitalize(item?.user?.userName)}</span>
      ),
    },
    {
      head: "Email",
      accessor: "Email",
      isComponent: true,
      component: (item, ind) => (
        <span className="text">{item?.user?.email}</span>
      ),
    },
    {
      head: "TransactionId",
      accessor: "chargeId",
    },
    //   {
    //     head: "Payment Method",
    //     accessor: "paymentMethod",
    //   },
    {
      head: "Payment Type",
      accessor: "paymentType",
      isComponent: true,
      component: (item, ind) => (
        <span className="text-text-capitalize">{item?.paymentType}</span>
      ),
    },
    {
      head: "Amount",
      accessor: "amount",
      isComponent: true,
      component: (item, ind) => (
        <span className="text flex ">
          {typeof item?.amount === "number"
            ? `$ ${item?.amount?.toFixed(4)}`
            : "0.0000"}
        </span>
      ),
    },
    {
      head: "Status",
      accessor: "status",
    },
  ];
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="ManageTeacher position-relative">
        <Container>
          <Row className="">
            <Col lg="12" className="my-2 ">
              <div className="d-flex align-items-center justify-content-between ">
                <h5 className="mb-0 py-3 fw-sbold text-capitalize">
                  All Subscription Payments
                </h5>
                <p className="mb-0 py-2 fw-sbold text-capitalize card px-3">
                  Total Amount: ${" "}
                  {(PAYMENTSLISTDATA?.data?.total &&
                    PAYMENTSLISTDATA?.data?.total?.toFixed(4)) ||
                    0}
                </p>
              </div>
            </Col>
            <Row className="mb-3  d-flex  align-items-center">
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={filter.search || ""}
                  onChange={(e) =>
                    setFilter((prev) => ({ ...prev, search: e.target.value }))
                  }
                />
              </Col>
              <Col md={6} className="d-flex gap-3 align-items-center">
                <Form.Select
                  className="py-2"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                </Form.Select>
                <div className="d-flex gap-3">
                  <Button
                    onClick={handleFilter}
                    variant="primary"
                    className="py-2 px-3"
                  >
                    Filter
                  </Button>
                  <Button
                    onClick={clearFilter}
                    variant="secondary"
                    className="py-2 px-3"
                  >
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm  rounded bg-white">
                {!loading && PAYMENTSLISTDATA?.data?.payments?.length > 0 && (
                  <>
                    <TableLayout
                      data={PAYMENTSLISTDATA?.data?.payments}
                      column={column}
                      totalCount={totalCount}
                      limit={filter.limit}
                      handlePageChange={handlePageChange}
                      pageNo={filter.pageNo}
                    />
                  </>
                )}
                {loading && PAYMENTSLISTDATA?.data?.payments?.length === 0 && (
                  <>
                    <div className="d-flex justify-content-center">
                      <p>No Data Found</p>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SubscriptionPayment;
